const theme = () => ({

      landingContainer:{
        position:"absolute",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-betwen",
        alignItems:"center",
        // border:"2px dashed red",
        background:"#495a69",

        height:"calc(100%)",
        width:"calc(100%)",
      },
      landingAppBar:{
        display:"flex",
        width:"calc(100%)",
        height:"4rem",

        // border:"2px dashed red",
      },
      landingBody:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-around",
        width:"calc(100%)",
        height:"calc(100%)",
        padding:"2rem",
        // border:"2px dashed red",
      },
      landingFooter:{
        display:"flex",
        width:"calc(100%)",
        height:"4rem",
        // border:"2px dashed red",
      },
      landingHeadlineWrapper:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        lineHeight:"1.5rem",
        width:"calc(100%)",
      },
      landingInfo:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width:"16rem",
        height:"9rem",
        lineHeight:"2.5rem",
        color:"#fff !important",
        borderRadius:"10px",
        border:"2px solid rgba(255,255,255,0.5)",
      },
      landingInfoWrapper:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        // borderRadius:"10px",
        // border:"2px solid rgba(255,255,255,0.5)",
      },
      landingOptions:{
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        marginLeft:"1rem",
        width:"calc(100% - 2rem)",
        // height:"15rem",
      },
      landingBox:{
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        width:"calc(100%)",
        height:"7rem",
        padding:"1rem",
        color:"#fff",
        borderRadius:"10px",
        // background:"rgba(0,0,0,0.2)",
        // border:"2px dashed red",
      },
      landingDivider:{
        display:"flex",
        justifyContent:"center",
        //margin:"1rem",
        color:"#fff",
        width:"8rem",
      },
      landingTitle:{
        fontWeight:900,
        fontSize:"2.5rem",
        color:"#fff",
        textTransform:"capitalize",
        paddingBottom:"0.5rem",
        borderBottom:"2px solid rgba(255,255,255,0.3)"
      },
      landingSubtitle:{
        fontWeight:600,
        fontSize:"1.1rem",
        color:"#ddd",
        textTransform:"uppercase",
      }
    
  });

  export default theme