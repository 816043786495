const theme = () => ({
   cardContainer:{
          width:"100%",
          height:"100%",
          overflow:"hidden !important",
          borderRadius:"5px",
          overflow:"hidden",
   },
   cardWrapper:{
          display:"flex",
          justifyContent:"space-between",
          flexDirection:"column",
          alignItems:"center",
        //   padding:"0.5rem 0.25rem 2rem 0.25rem",
          width:"calc(100%)",
          height:"calc(100%)",
          overflow:"hidden",
          
   },
   test:{
       border:"2px dashed red",
   },
   cardMediaContainer:{
       
       boarderRadius:"10px",
       width:"calc(100% )",
       height:"calc(100%)",
       borderRadius:"5px",
       overflow:"hidden !important",
       //border:"2px dashed red",
   },
   cardMediaWrapper:{
    width:"calc(100%)",
    height:"calc(100%)",
    // border:"2px dashed red",
   },
     cardImg:{
        //  position:"absolute",
        marginTop:"0",
       marginLeft:"0",
       width:"calc(100%) ",
       height:"calc(100%)",
       objectFit:"contain",
       objectPosition:"50% 50%",
       zIndex:1,
   },
   cardImgBack:{
    // position:"absolute",
       width:"102%",
       height:"102%",
       transform:"scale(1.1)",
       zIndex:0,
       opacity:0.2,
       objectFit:"cover",
   },
   cardActionContainerWrapper:{
       display:"flex",
       flexDirection:"column",
       justifyContent:"space-between",
       width:"calc(100%)",
       height:"calc(100%)",
       padding:"0",
       margin:0,
       
       zIndex:"300",
       //border:"4px dashed red",
},
   cardActionStepContainer:{
    display:"flex",
    flexDirection:"column",
    width:"calc(100%)",
    paddingBottom:"0.5rem",
    margin:"0",
    background:"rgba(33,41,49,1)",
    // border:"2px dashed blue",

   },

   cardImgContainer:{
       marginTop:"0.25rem",
       marginLeft:"0.25rem",
       marginBottom:"0.25rem",
       width:"calc(100% - 0.5rem)",
       borderRadius:"5px",
       height:"calc(100%)",
        background:"rgba(255,255,255,0.05)",
       overflow:"hidden",
      
   },
   cardImageContainerBackdrop:{
      //position:"absolute",
       width:"100%",
       height:"100%",
    //   border:"2px dashed red",
   },
   cardActionContainer:{
    display:"flex",
    flexDirection:"column",
   
    width:"calc(100%)",
    padding:"1rem 0 0.25rem 0",
    background:"rgba(33,41,49,1)",
 // border:"2px dashed blue",
},
   cardActionStepWrapper:{
       display:"flex",
       flexDirection:"column",
       borderRadius:"0 0 5px 5px",
       padding:"0",
       width:"calc(100%)",
       zIndex:"2",
        // border:"2px dashed blue",
   },
   action:{
       marginLeft:"0.75rem",
       width:"calc(100% - 1.5rem)",
       fontSize:"1rem",
       textAlign:"center",
       color:"#eee",
       fontWeight:"400",
       padding:"0 0rem 0.5rem 0rem",
       borderBottom:"2px solid rgba(255,255,255,0.1)",
   },
   actionStep:{
    marginLeft:"0.5rem",
    width:"calc(100% - 1rem)",
       padding:"1rem 0 0 0",
       textAlign:"center",
       fontWeight:"100",
       fontSize:"1.2rem",
       borderTop:"2px solid rgba(255,255,255,0.1)",

   color:"#ddd",
   },
    
   flashTopicWrapper:{
       display:"flex",
       justifyContent:"center",
       width:"100%",
       height:"100%",
       //border:"2px dashed red",
   },
   flashTopic:{
       display:"flex",
       justifyContent:"center",
       alignItems:"center",
       height:"100%",
       fontSize:"10vw",
       color:"rgba(255,255,255,1)",
       fontWeight:"900",
       textAlign:"center",
       textTransform:"uppercase",
       padding:"1rem",
       zIndex:"20",
       textShadow:"2px 2px 10px rgba(0,0,0,0.5)",
       //border:"2px dashed red",
   },
   flashTopicBackdrop:{
       position:"absolute",
       width:"100%",
       height:"100%",
       background:"rgba(0,0,0,0.8)",
       zIndex:"19",
       opacity:0,
       borderRadius:"5px",
       // border:"3px dashed red",
   },
   swipeUpContainer:{
       display:"flex",
       flexDirection:"column",
       justifyContent:"space-between",
       alignItems:"center",
       padding:"0rem 0 0.25rem 0",
    //    background:"rgba(0,0,0,0.2)",
       //border:"2px dashed red",
   },
   swipeUpIcon:{
       position:"absolute",
       //height:"4rem !important",
       background:"rgba(0,0,0,0)",
       fontSize:"2rem !important",
       color:"rgba(200,200,200,1) !important",
       padding:"0 !important",
       margin:"0 !important",
       textShadow:"10px 10px 1px rgba(0,0,0,1)",
       //border:"2px dashed red",
   },
   swipeUpText:{
       fontSize:"0.6rem !important",
       color:"rgba(200,200,200,1) !important",
       padding:"0 !important",
       margin:"0 !important",
       textShadow:"1px 1px 1px rgba(0,0,0,0.6)",
   },
   imgPlaceholder:{
       width:"100%",
       height:"100%",
       background:"#fff"
   },
   upIndicator:{
       width:"100%",
       height:"2rem",
       display:"flex",
       justifyContent:"center",
       color:"rgba(255,255,255,0.3) !important"
   },
   highlight:{
       color:"yellow",
   }

});

export default theme