import React, {useState, useEffect, useContext} from 'react'
import globalStyles from "../styles/globalStyles"
import {CircularProgress} from "@material-ui/core"
import navStyles from "../styles/navbarStyles"
import contentStyles from "../styles/contentStyles"
import {ListContext} from "../context/ListState"

import {IconButton, 
    Drawer, 
    Avatar, 
    //CircularProgress
} from "@material-ui/core"
import {Menu, 
    //Fullscreen, 
    //FullscreenExit, 
    KeyboardArrowLeft, 
    AccountCircle
} from "@material-ui/icons"
import {createUseStyles} from 'react-jss'
//import classes from '*.module.css'

const useStyles = createUseStyles({
    ...globalStyles(),
    ...navStyles(),
    ...contentStyles(),
})

function Navbar() {
    const classes = useStyles();
    const [{
        activeTopic,
        activeStep,
        listItems,
      },
      //listDispatch,
      ] = useContext(ListContext);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
   // const [isFullscreen, setIsFullscren] = useState(false);
    useEffect(()=>{

    },[drawerIsOpen])
  

    return (
        <div className={classes.navbar}>
           {/* <div className={classes.navButtonBlockLeft}>
               <IconButton onClick={()=> setDrawerIsOpen(true)}><Menu fontSize="large" className={classes.navButton}/>
               </IconButton>
            </div> */}
           <div className={classes.navMid}></div>
           <div className={classes.navButtonBlockRight}>
           
            </div>
           <Drawer classes={{
               paper:classes.drawer,
           }}
           anchor="left" 
           open={drawerIsOpen} 
           onClose={()=> setDrawerIsOpen(false)}
           >
           <div>
               <div className={classes.drawerHeader}>
                   <div className={classes.drawerHeaderBlock}>
                       <Avatar  style={{height:"3rem", width:"3rem"}} >
                            <AccountCircle className={classes.profileIcon}  />
                       </Avatar>
                       
                    </div>
                   <div className={classes.drawerHeaderBlock}>
                       <span className={classes.drawerProfileName}>Guest User</span><br/>
                       <span className={classes.drawerProfileEmail}>person@example.com</span>
                   </div>
                   <div className={classes.drawerHeaderBlock}>
                        <IconButton  onClick={()=> setDrawerIsOpen(false)}>
                            <KeyboardArrowLeft className={classes.navButton} fontSize="large" />
                        </IconButton>
                    </div>
               
               </div>
              
            </div>
           </Drawer>
        </div>
    )
}

export default Navbar
