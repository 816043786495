import React from 'react'

function Announcements() {
    return (
        <div>
            No Announcements.
        </div>
    )
}

export default Announcements
