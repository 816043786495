const checkList = (state, action) => {
    switch (action.type) {
      // case "swipeDirection":
      //   return { ...state, activeTopic: action.payload };
      case "activeTopic":
        return {
           ...state, activeTopic: action.activeTopic, swipeDirection: action.swipeDirection };
      case "activeStep":
        return { ...state, activeStep: action.payload };
      case "selectedList":
        return { ...state, selectedList: action.payload };
        case "setLists":
          return { ...state, lists: action.payload };
      default:
        return state;
      }
};
      
export default checkList