import React from 'react'
import globalStyles from "../styles/globalStyles"
import beginStyles from "../styles/beginStyles"
//import {Button} from "@material-ui/core"
import QrIcon from "../icons/qrIcon"
import {CircularProgress} from "@material-ui/core"
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    ...globalStyles(),
    ...beginStyles()
})
function Loading() {
    const classes = useStyles();
    //const [modOpen, setModOpen] = useState(false);
    const handleOpen = () => {
      //  setModOpen(true);
      };
    
    //   const handleClose = () => {
    //   setModOpen(false);
    //   };
    // console.log("this is landing")
    return (
      
    <div className={classes.beginContainer} >
        <div className={classes.header}></div>
        <div className={classes.landingContainer}>
            <div className={classes.landingScanDiv}>
                <div className={classes.scanDiv}></div>
            </div>
            <div className={classes.beginLoadingContainer}>
            <div className={classes.beginLoading} >
                <CircularProgress className={classes.beginLoader}/>
                <span>Loading</span>
            </div>
            </div>
          
            <div className={classes.landingLoginDiv}>
            <div className={classes.loginDiv}></div>
            </div>
        </div>
        <div className={classes.footer}></div>
   </div>
    )
}

export default Loading
