import React, { createContext, useReducer } from "react";
import ListReducer from "./ListReducer";

const initialState = {
    activeTopic:-1,
    activeStep:0,
    swipeDirection:null,
    params:{
      building:null,
      room:null,
      organization:null,
    },
    lists:[],
    selectedList:0,
   
      }   
      


export const ListContext = createContext(initialState);
export const ListProvider = ({children}) => {
    const [state, dispatch] = useReducer(ListReducer, initialState);
    return (
        <ListContext.Provider value={[state, dispatch]}>
        {children}
      </ListContext.Provider>
    );
}; 