const theme = () => ({
    topic:{
      // position:"absolute",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      fontSize:"1.3rem",
      fontWeight:"600",
      color:"#D6F9DD",
      //padding:"0rem 2rem",
      width:"calc(100%)",
      opacity:1,
      textTransform:"uppercase",
     // overflow:"hidden",
      whiteSpace:"nowrap",
      //  border:"2px dashed red",
    },
    topicWidthFill:{
      color:"rgba(0,0,0,0) !important",
    },
    topicInfo:{
      //position:"absolute",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      //marginLeft:"1rem",
      width:"5.5rem",
      //height:"3rem",
      color:"#C5D6D8",
      // border:"2px dashed red",
    },
    // topicInset:{
    //   display:"flex",
    //   justifyContent:"center",
    //   alignItems:"center",
    //   width:"calc(100%)",
    //   //height:"2rem",
    //   //margin:"0 0.5rem",
    //   padding:"0.4rem 1rem 0.4rem 1rem",
    //   // background:"#37444F",
    //   // borderRadius:"5px",
    //   // border:"1px solid rgba(255,255,255,0.2)",
    //   // boxShadow:" inset 2px 2px 2px #303c46, inset -2px -2px 2px #3e4c58",
    //   overflow:"hidden !important",
    //         // border:"2px dashed red",

    // },
    topicSlider:{
      //position:"absolute",
     
      width:"100vw !important",
      // border:"2px dashed red",
    },
  
  
    infoIconContainer:{
      position:"absolute",
      padding:"0.25rem",
      //width:"5.5rem",
      height:"3rem",
      display:"flex",
      //background:"#fff",
      justifyContent:"center",
      alignItems:"center",
      // border:"2px dashed red",
      zIndex:3000,
    },
    infoIcon:{
      borderRadius:"100%",
      width:"2rem !important",
      height:"2rem !important",
      //background:"#fff !important",
      color:"rgba(156, 246, 246,0.9) !important",
     
    },
    imageBackdrop:{
      //position:"absolute",
      width:"100%",
      height:"100%",
      objectFit:"fill",
      zIndex:"0",
      filter:"contrast(100%) brightness(90%) blur(10px)",
    },
    topicTextContainer:{
      display:"flex",
      overflow:"hidden",
      marginBottom:"0.5rem",
    },
    topicProgressBar:{
      // position:"absolute",
      display:"flex",
      justifyContent:"space-evenly",
      alignItems:"flex-end",
      width:"calc(100% - 1rem )",
      //height:"0.75rem",
      padding:"0.25rem 0.5rem",
      borderRadius:"3px",
      zIndex:1,
      // border:"2px dashed red",
    },
    topicProgressBarActive:{
      position:"absolute",
      display:"flex",
      justifyContent:"space-evenly",
      alignItems:"flex-end",
      width:"calc(100% - 1rem )",
      //height:"0.75rem",
      padding:"0.25rem 0rem",
      borderRadius:"3px",
      zIndex:2,
      // border:"2px dashed red",
    },
    topicProgressContainer:{
      width:"100%",
    },
    stepProgressContainerRight:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      margin:"0.3rem 0.25rem 0.4rem 0.2rem",
      justifyContent:"space-evenly",
      alignItems:"flex-end",
      height:"calc(100% - 0.8rem)",
      width:"0.25rem",
      //borderRadius:"2px",
      boxShadow:"inset 0px 4px 5px rgba(0,0,0,0.15), inset 0px -4px 5px rgba(0,0,0,0.15), 0px 8px 10px rgba(0,0,0,0.15), 0px -2px 5px rgba(0,0,0,0.15) ",
      // background:"rgba(156, 246, 246,0.9) !important",
      background:"rgba(0, 0, 0,0.2) !important",
      overflow:"hidden",
    },
    stepProgressContainerLeft:{
      position:"relative",
      display:"flex",
      flexDirection:"column",
      margin:"0.3rem 0.2rem 0.4rem 0.25rem",
      justifyContent:"space-evenly",
      alignItems:"flex-end",
      height:"calc(100% - 0.8rem)",
      width:"0.25rem",
      //borderRadius:"2px",
      boxShadow:"inset 0px 4px 5px rgba(0,0,0,0.15), inset 0px -4px 5px rgba(0,0,0,0.15), 0px 8px 10px rgba(0,0,0,0.15), 0px -2px 5px rgba(0,0,0,0.15) ",
      // background:"rgba(156, 246, 246,0.9) !important",
      background:"rgba(0, 0, 0,0.2) !important",
      overflow:"hidden",
    },
    stepProgressBar:{
     width:"calc(100%)",
     height:"100%",
    //  background:"rgb(110,122,134)",
    // background:"rgba(156, 246, 246,0.3) !important",
    background:"rgba(106,159,163,0.8)",
     //transform:"translate3D(0,-100%,0)",
     //border:"2px solid rgba(0,0,0,0.3)",
    },
    swipeSpace:{
      width:"100%",
      height:"100%",
      overflow:"hidden",
      //border:"2px dashed red",
    },
    
    topicStep:{
      // background:"rgba(255,255,255,0.2)",
      background:"rgba(0, 0, 0,0.2) !important",
      margin:"0 0.1rem",
      height:"0.3rem",
      width:"100%",
      borderRadius:"2px",
      // border:"2px dashed red"
    },
    hiddenTopic:{
      background:"rgba(255,255,255,0)",
      margin:"0 0.1rem",
      height:"0.3rem",
      width:"100%",
      borderRadius:"2px",
    },

    activeTopic:{
      // background:"rgba(156, 246, 246,1) !important",
      background:"rgb(106,159,163)",
      // background:"rgba(200,200,200,1)",
      margin:"0 0.1rem",
      height:"0.3rem",
      width:"100%",
      borderRadius:"2px",
      // border:"2px dashed red"
    },
    // completedStep:{
    //   background:"Yellow",
    //   margin:"0 0.25rem",
    //   height:"0.25rem",
    //   width:"100%",
    //   borderRadius:"50px",
    //   // border:"2px dashed red"
    // },
    topicContainerSideFill:{
      display:"flex",
      marginLeft:"1rem",
      justifyContent:"center",
      alignItems:"center",
      width:"5rem",
      height:"3rem",
      //background:"#fff",
      // border:"2px dashed red",
    },
    topicContainerSideFillRight:{
      display:"flex",
      marginRight:"1rem",
      justifyContent:"center",
      alignItems:"center",
      width:"5rem",
      height:"3rem",
      //background:"#fff",
      // border:"2px dashed red",
    },
    // linear:{
    //   height:"0.75rem !important",
    //   color:"#ADB6C4 !important"
    // },
    // linearColor:{
    //   background:"#ADB6C4 !important",
    // },
   
    // linearBar:{
    //   background:"rgba(26,39,51,1) !important",
    // },
    messageSpace:{
      position:"absolute",
      width:"100%",
      height:"100%",
      bottom:"0",
      opacity:0,
      
      zIndex:3,
    },
    infoSpace:{
      marginLeft:"2rem",
      marginTop:"2rem",
      background:"#fff",
      borderRadius:"5px",
      // padding:"2rem",
      width:"calc(100% - 4rem)",
      height:"calc(100% - 4rem)",
      background:"rgba(26,39,51,1)",
      color:"#fff",
      // border:"2px dashed red",
    },
    infoBackdrop:{
      position:"absolute",
      background:"#fff",
      borderRadius:"5px",
      width:"calc(100%)",
      height:"calc(100%)",
      background:"rgba(0,0,0,0.8)",
      opacity:0,
      zIndex:-1,
      // border:"2px dashed red",
    },
    closeInfo:{
      position:"relative",
      display:"flex",
      justifyContent:"flex-end",
      alignItems:"center",
      paddingTop:"0.75rem",
      paddingRight:"0.75rem",
      color:"#fff",
      width:"calc(100% - 0.5rem)",
      height:"2rem",
      // border:"2px dashed red",
    },

  
  });

  export default theme