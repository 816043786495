
import React, { useContext, useEffect, useRef } from 'react'
import {ListContext} from "../context/ListState";
import {SendContext} from "../context/SendState";
import {LogContext} from "../context/LogState";

import {createUseStyles} from 'react-jss';
import beginStyles from "../styles/beginStyles";
import {gsap, TimelineMax} from "gsap";
import { API, Auth, Hub } from"aws-amplify";

import "../styles/App.css"

const useStyles = createUseStyles({
    ...beginStyles(),
  })
function EndCard({selected,setSelected}) {
    const endContainerRef = useRef();
    const endWrapperRef = useRef();
    const button1Ref = useRef();
    const button2Ref = useRef();
    let tlButtonAnimation = new TimelineMax();
    const classes = useStyles();
    const [{
        lists,
        selectedList,
        activeTopic,
    },
  listDispatch,
    ] = useContext(ListContext);
    const [{
        queryParams,
    },
  sendDispatch,
    ] = useContext(SendContext);
    const [{
        log
    },
   logDispatch,
    ] = useContext(LogContext);
    useEffect(()=>{
       exitAnimation();
    },[])
    useEffect(()=>{
        closeSessionLog()
    },[])

    function exitAnimation(){
        gsap.set([endContainerRef.current],{
            css:{
                background:"#495a69"
            },
            ease:"power3_out",
        })
        gsap.to([endContainerRef.current],1,{
            delay:0.2,
            css:{
                background:"#212931"
            },
            ease:"power3_out",
        })
        tlButtonAnimation
        .set([button1Ref.current, button2Ref.current],{
            opacity:0,
            y:-10,
        })
        .staggerTo([button1Ref.current, button2Ref.current],0.5,{
            delay:0.5,
            opacity:1,
            y:0,
        },0.25)
       
    }
    function goToCards(){
        listDispatch({type:"activeTopic", activeTopic: 0})
    }
    async function goToBegin(){
        //await sendDispatch({type:"queryParams", queryParams:""})
        await listDispatch({type:"activeTopic", activeTopic: -1})
        setSelected(false)
    }
    async function closeSessionLog(){
        const params ={
            body:{
                    ...log
            }
        }
       try{
            const res = await API.put("listLogsApi3","/listlogs3",params)
            //console.log("closingSession",res)
        } 
        catch(err){
            console.log("error sending begin session log", err)
        }
    }
    return (
        <div className={classes.beginContainer} ref={endContainerRef}>
           <div className={classes.locationTitle}>{lists[selectedList].location.building.toUpperCase()}-{lists[selectedList].location.room.toUpperCase()}</div>

            <div className={classes.endWrapper} ref={endWrapperRef}>
                   <button className={classes.endButton} ref={button1Ref} onClick={()=>goToCards()}>Start Again</button>
                   <button className={classes.endButton} ref={button2Ref} onClick={()=>goToBegin()}>Return to Lists</button>
            </div>
        </div>
    )
}

export default EndCard
