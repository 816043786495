import React, {useRef, useEffect, useContext} from 'react'
import {createUseStyles} from 'react-jss';
import globalStyles from "../styles/globalStyles";
import swipeUpStyles from "../styles/swipeUpStyles";
import {gsap, TimelineMax} from "gsap";

import {ListContext} from "../context/ListState";

const useStyles = createUseStyles({
    ...globalStyles(),
    ...swipeUpStyles(),
  })
function SwipeUp() {
    const classes = useStyles();
    const swipeCircleRef = useRef();
    const swipeWrapperRef = useRef();
    let tlSwipeUpWrapperAnimation = new TimelineMax();
    let tlSwipeUpCircleAnimation = new TimelineMax();

    const [{
        activeTopic
    },
    dispatchList
    ] = useContext(ListContext)
    useEffect(()=>{
        swipeUpAnimation()
    },[activeTopic])

    function swipeUpAnimation(){
       tlSwipeUpWrapperAnimation
       .set([swipeWrapperRef.current],{
           opacity:0
       })
       .to([swipeWrapperRef.current],0.5,{
           delay:0.5,
           opacity:1,
           ease: "slow(0.7, 0.7, false)"
       })
       .to([swipeWrapperRef.current],0.5,{
           opacity:0
       })
    //    tlSwipeUpCircleAnimation
    //    .set([swipeCircleRef.current],{
    //     y:0,
    //    })
    //    .to([swipeCircleRef.current],0.5,{
    //        delay:0.5,
    //         y:-100 + "%",
    //         //height:"5rem",
    //         //alignItems:"flex-start",
    //         ease:"power2.in"
    //    })
      
    }
    return (
        <div className={classes.swipeUpContainer}>
           <div className={classes.swipeUpWrapper} ref={swipeWrapperRef}>
                <div className={classes.swipeUpCircle} ref={swipeCircleRef}></div>
           </div>
        </div>
    )
}

export default SwipeUp
