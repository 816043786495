import React, {useEffect,useRef, useState, useContext} from 'react'
import {gsap, TimelineMax} from "gsap";
import msgStyles from "../styles/msgStyles";
import globalStyles from "../styles/globalStyles";
import {SendContext} from "../context/SendState";
import {Send} from "@material-ui/icons"
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    ...msgStyles(),
    ...globalStyles(),
})


function Messenger({messageOpen,openFn, myMessage, impactOpen}) {
    // const {messageOpen, openFn} = props;
    const [{
       
       },
      sendDispatch,
       ] = useContext(SendContext);
 
    const typeSpaceRef = useRef();
    const msgInputRef = useRef();
    const classes = useStyles();
    const [section,setSection]=useState(null);
    useEffect(()=>{
        toggleTypeSpace();
    },[messageOpen])
    function toggleTypeSpace(){
        if (messageOpen){
           
            gsap.to([typeSpaceRef.current],0.35,{
                delay:0.2,
                y:0,
                ease:"power3.out"
            })
            setTimeout(()=>{
                msgInputRef.current.focus()
            },350)
        }else{
            msgInputRef.current.blur()
            gsap.to([typeSpaceRef.current],0.35,{
                y:100 + "%",
                ease:"power3.out"
            })
            
        }
    }

  async  function submitMessage(){
     // await msgInputRef.current.blur()
      openFn(false)
        impactOpen(true)
       await sendDispatch({type:"message", payload:msgInputRef.current.innerText})
        await myMessage(msgInputRef.current.innerText)

        msgInputRef.current.innerText = ""
        
    }
    return (
        <div className={classes.msgBackdrop}>
           <div className={classes.typeSpace} ref={typeSpaceRef}>
                <div className={classes.typeSpaceDesciption}>Send a message to Admins:</div>
                <div className={classes.inputSpace}>
                    <div contentEditable className={classes.msgInput} ref={msgInputRef}></div>
                    
                </div>
                <div className={classes.msgButtonDiv}><button className={classes.invisibleButton} onClick={submitMessage}><Send className={classes.msgButton} /></button></div>
            </div>
        </div>
    )
}

export default Messenger
