const logEvents = (state, action) => {
    switch (action.type) {
     
      case "logBegin":
        return { ...state, log: {
          ...state.log,
          id:action.payload.id,
          listName:action.payload.listName,
          location:action.payload.location,
          time:[action.payload.time],
          timestamp:[action.payload.timestamp],
        } };
      case "logBrowser":
        return { ...state, log: {
          ...state.log,
          device:action.payload
        } };
      case "interaction":
        return { ...state, log: {
          ...state.log,
          interactions:[
            ...state.log.interactions,
            action.payload
          ]
        } };
        case "message":
          return { ...state, log: {
            ...state.log,
            message:action.payload
          } };
      default:
        return state;
      }
};
      
export default logEvents