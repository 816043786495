import React, { createContext, useReducer } from "react";
import ReportReducer from "./ReportReducer";

const initialState = {
        reports:{
          impact:"select"
        }
   
      }   
      


export const ReportContext = createContext(initialState);
export const ReportProvider = ({children}) => {
    const [state, dispatch] = useReducer(ReportReducer, initialState);
    return (
        <ReportContext.Provider value={[state, dispatch]}>
        {children}
      </ReportContext.Provider>
    );
}; 