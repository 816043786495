import React, {useContext, useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import globalStyles from "../styles/globalStyles";
import landingStyles from "../styles/landingStyles";
import {PlayArrow} from "@material-ui/icons"
import "../styles/App.css"

const useStyles = createUseStyles({
    ...globalStyles(),
    ...landingStyles(),
  })

function Landing() {
    const classes = useStyles();

    return (
        <>
        <div className={classes.landingContainer}>
            <div className={classes.landingAppBar}></div>
            <div className={classes.landingBody}>
                <div className={classes.landingHeadlineWrapper}>
                    <div className={classes.landingTitle}>Sensory Cards</div>
                    <div className={classes.landingSubtitle}>Guides that move with you</div>
                </div>
                <div className={classes.landingInfoWrapper}>
                    <div className={classes.landingInfo}>
                        <div><PlayArrow/></div>
                        <div> What are Sensory Cards?</div>
                    </div>
                </div>
                <div className={classes.landingOptions}>  
                    <div className={classes.landingBox}>Scan a QR code with your Camera App</div>
                    <div className={classes.landingDivider}>-or-</div>
                    <div className={classes.landingBox}>Login here</div></div>
            </div>
            <div className={classes.landingFooter}></div>
        </div>
   </>
    )
}

export default Landing
