import React, {useState, useContext} from 'react'
import ListSelect from "../components/ListSelect"
import SelectReports from "../components/SelectReports"
import Announcements from "../components/Announcements"
import globalStyles from "../styles/globalStyles";
import beginStyles from "../styles/beginStyles";
import {ListContext} from "../context/ListState";
import {createUseStyles} from 'react-jss';
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core"
import {AssignmentTurnedIn, Feedback, NewReleases} from "@material-ui/icons"

const useStyles = createUseStyles({
    ...globalStyles(),
    ...beginStyles(),
  })

function Select({selected, setSelected}) {
    const [{
        activeTopic,
        lists,
        selectedList
    },
   listDispatch,
    ] = useContext(ListContext);
    const classes = useStyles();
    const [navSelect, setNavSelect]=useState(1)
  
    function navChoices(){
        if (navSelect ===1){
            return <ListSelect selected={selected} setSelected={setSelected}/>
        } else if (navSelect === 2){
            return <SelectReports/>
        } else if (navSelect === 3){
            return <Announcements/>
        }else{
            return
        }
    }
    function handleNavSelectChange(e, f){
        // console.log(e,f)
        setNavSelect(f)
    }
    return (
        <div className={classes.selectContainer}>
            <div className={classes.locationTitle}>{lists[selectedList].location.building.toUpperCase()}-{lists[selectedList].location.room.toUpperCase()}</div>
            <div className={classes.selectBody}>
                {navChoices()}
            </div>
            <div className={classes.selectFooter}>
                <BottomNavigation
                    value={navSelect}   
                    onChange={(e,f)=> handleNavSelectChange(e,f)}
                    showLabels
                    className={classes.bottomNavigation}
                >
                    <BottomNavigationAction 
                        classes={{
                            root:classes.bottomNavAction,
                            selected:classes.bottomNavSelected
                        }}
                        value={1} label="Checklists" icon={<AssignmentTurnedIn/>} />
                    <BottomNavigationAction 
                        classes={{
                            root:classes.bottomNavAction,
                            selected:classes.bottomNavSelected
                        }}
                        value={2} label="Reports" icon={<Feedback/>} />
                    <BottomNavigationAction 
                        classes={{
                            root:classes.bottomNavAction,
                            selected:classes.bottomNavSelected
                        }}
                        value={3} label="Announcements" icon={<NewReleases/>} />
                </BottomNavigation>
            </div>
        </div>
    )
}

export default Select
   