
import React, { useContext, useEffect, useRef, useState } from 'react'
import {ListContext} from "../context/ListState";
import {ReportContext} from "../context/ReportState";
import {SendContext} from "../context/SendState";
import {createUseStyles} from 'react-jss';
import {gsap, TimelineMax} from "gsap";
import {CircularProgress} from "@material-ui/core"
import globalStyles from "../styles/globalStyles";
import beginStyles from "../styles/beginStyles";

import {AssignmentTurnedIn, DragHandle} from "@material-ui/icons"
import "../styles/App.css"
import BeginCard from './BeginCard';
import { filter } from 'dom7';

const useStyles = createUseStyles({
    ...globalStyles(),
    ...beginStyles(),
  })
function ListSelect({selected, setSelected}) {
    const classes = useStyles();
    const [{
        activeTopic,
        lists,
        selectedList
    },
   listDispatch,
    ] = useContext(ListContext);
    const [{
       queryParams
    },
   sendDispatch,
    ] = useContext(SendContext);
    const [{
       reports,
    },
   reportDispatch,
    ] = useContext(ReportContext);
    function handleListSelect(l){
        listDispatch({type:"selectedList", payload:l.index})
        setSelected(true)
    }
    const [myReports,setMyReports]=useState([]);
  
    


    if (!selected){
        return (
               <div className={classes.listWrapper}>
                   <div className={classes.listTitle}> Select a list to view:</div>
                   <div className={classes.listUl}>
                    {lists.map((items,index)=>{
                          return (
                              <div key={index} className={classes.listLi}><button className={classes.hiddenButton} onClick={()=>handleListSelect({index})}>{items.subject}</button></div>
                          )
                      })}
                   </div>
                   </div>
        )
    }else{
        return (
            <BeginCard selected={selected} setSelected={setSelected}/>
            )
    }
   
}

export default ListSelect
