import React, {useContext, useEffect, useRef, useState} from 'react';
import {ListContext} from "../context/ListState";
import {LogContext} from "../context/LogState";
import Swiper from 'react-id-swiper';
import swiperStyles from "../styles/swiperStyles";
import Card from "./Card"
import {createUseStyles} from 'react-jss';
import SwiperCore, {Keyboard, EffectCoverflow, EffectFlip, EffectFade, Virtual} from 'swiper';
import { SwiperSlide } from 'swiper/react';
import gsap from "gsap";
import $ from "dom7";
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';


SwiperCore.use([Keyboard, EffectCoverflow, EffectFlip, EffectFade, Virtual]);

const useStyles = createUseStyles({
  ...swiperStyles()
})


function SimpleSwiper() {
  const classes = useStyles();
  const horizontalRef = useRef([]);
  const verticalRefs = useRef([]);
   const slideRefs = useRef([]) 
   const swipeRef = useRef();
  const [thisCard, setThisCard] =useState([0,0])
  const [moveH, setMoveH] =useState(0)
  const [moveV, setMoveV] =useState(0)
  const [skipV, setSkipV] = useState(0)

  const [{
    activeTopic,
    activeStep,
    lists,
    selectedList,
  },
   listDispatch,
  ] = useContext(ListContext);
  const [{
   log
  },
   logDispatch,
  ] = useContext(LogContext);
  const listItems = lists[selectedList].listItems;
  useEffect(()=>{
    CardEnterAnimationTimeline()
  },[])
  useEffect(()=>{
    if (moveH !== 0 || moveV !== 0){
        if (skipV !== 0 && moveV < 0){
          setSkipV(0)
        }else{
          updateInteraction()
        }
    }
   
    setMoveH(0)
    setMoveV(0)
  },[thisCard])
  
  verticalRefs.current = listItems.map(
    (ref, index) => verticalRefs.current[index] = React.createRef()
    )
  slideRefs.current = listItems.map(
    (ref, index) => verticalRefs.current[index] = React.createRef()
    )
  
    function CardEnterAnimationTimeline(){
      gsap.set([horizontalRef.current],{
          y:100 + "%",
      })
      gsap.to([horizontalRef.current],0.75,{
          delay:0.5,
          y:0 + "%",
          ease: "back.out(1.2)",
      })
    }
  function createSwiperColumn(){
    const res = listItems.map((slideContent, index) => {
      return (
              <div key={slideContent.topic} >
                  <Swiper {...vParams} ref={verticalRefs.current[index]}>
                    {createSwiperRow(index, slideContent.action)}
                    <div></div>
                  </Swiper>
              </div>
              );
    })
  return res
  }
   
  function createSwiperRow(i,a){
    const res = listItems[i].steps.map((step, index) => {
      return (
              <div key={index} >
                  <div className={classes.slide}>
                   
                    <Card cardIndex={index} cardTopic={i} cardH={""} cardV={""}  
                    //ref={slideRefs.current[index].current}
                    />
                  </div>
              </div>
              );
    })

  return res
  }

  function updateInteraction(){
   
    logDispatch({type:"interaction",payload:{
      card:thisCard,
      moveH,
      moveV,
      timestamp:Date.now(),
      message:0
    }})
    //  console.log({card:thisCard,moveH,moveV})
    //  setMoveH(0)
    //  setMoveV(0)
    //  setThisCard(0)
  }

    const hParams = {
      preventInteractionOnTransition:true,
      keyboard: {
        enabled: true,
      },
      progress:true,
      watchSlidesProgress: true, // enable the 'proress' property on each slide
      watchSlidesVisibility: true, // slides that in viewport will have additional visible class
      spaceBetween:200,
      //virtual:true,
      // lazy:true,
      on:{
        init(swiper){
          // console.log("swiper", swiper)
        },
        progress(swiper,progress){
        //  console.log("progress",moveH)
        },
        slideChange:function (e) {
          
          setMoveH(e.activeIndex - e.previousIndex)  
          setSkipV(1)  
          setThisCard([e.activeIndex, thisCard[1]])
          //setThisCard(e.activeIndex - e.previousIndex)  
          listDispatch(
            {type:"activeTopic", activeTopic: e.activeIndex, swipeDirection: e.touches.diff },
          )
          
        },
        slideChangeTransitionEnd:function (e) {
          verticalRefs.current[horizontalRef.current.swiper.previousIndex].current.swiper.slideTo(0,0,true)
        },
      },
    }
    
    const vParams = {
      // lazy:true,
      keyboard: {
        enabled: true,
      },
      spaceBetween:25,
      //virtual:true,
     //preventInteractionOnTransition:true,
      // progress:true,
      direction: 'vertical',
      // watchSlidesProgress: true, // enable the 'proress' property on each slide
      // speed: 50, // Set the speed of your animation in ms
      // virtualTranslate: true, // makes the slider not move automatically, you'll have to do the translate animation
      // watchSlidesVisibility: true, // slides that in viewport will have additional visible class
      // effect:"cardDeck", // optional - set the name of your animation. You can later check if your animation is actually applied and abort animation if not.
      on:{
      
        setTransition(swiper, duration) {
         if (swiper.params.effect !== "cardDeck") return;
        //   var slides = swiper.slides,
        //   activeIndex = swiper.activeIndex,
        //   $wrapperEl = swiper.$wrapperEl;
        //   slides.transition(duration).find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left').transition(duration);
        //   if (swiper.params.virtualTranslate && duration !== 0) {
        //     var eventTriggered = false; // eslint-disable-next-line
        //     slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
        //       if (eventTriggered) return;
        //       if (!swiper || swiper.destroyed) return; // if (!$(this).hasClass(swiper.params.slideActiveClass)) return;
        //       eventTriggered = true;
        //       swiper.animating = false;
        //       var triggerEvents = ['webkitTransitionEnd', 'transitionend'];
        //       for (var i = 0; i < triggerEvents.length; i += 1) {
        //         $wrapperEl.trigger(triggerEvents[i]);
        //       }
        //     });
        //   }
        },
        setTranslate(swiper, translate) {
          if (swiper.params.effect !== "cardDeck") return;
          // const slides = Object.values(swiper.slides).slice(0, -1);
         
          // slides.map((slide, index) => {
          //   const sl = swiper.slides.eq(index)
          //   const progress = slide.progress;
          //   const offset = slide.swiperSlideOffset;
          //   let tx = 0;
          //   let ty = -offset
          //   if (!swiper.params.virtualTranslate) ty -= swiper.translate;
          //   var slideOpacity = !swiper.params.fadeEffect.crossFade ? Math.max(1 - Math.abs(progress), 0) : 1 + Math.min(Math.max(progress, -1), 0);
          //   var slideScale = !swiper.params.fadeEffect.crossFade ? Math.max(1 - Math.abs(progress), 0) : 1 + Math.min(Math.max(progress, -1), 0);
          //   let translateSlide = slide ? ty : translate

          //   const clip = (val, min, max) => Math.max(min, Math.min(val, max));
          //   const clippedProgress = clip(slide.progress, -1, 1);
          //   const opacity = Math.max(1 - Math.abs(slide.progress), 0);
          
          //   gsap.to(
          //     slide
          //     , {
          //     opacity,
          //    y:translate,
          //   });
          
          // })
          
        },
        slideChange:function (e) {
          // if(log.interactions[log.interactions.length-1]){
            // console.log("moveH",log.interactions)
          // }
          setMoveV(e.activeIndex - e.previousIndex)  
          setThisCard([horizontalRef.current.swiper.activeIndex,e.activeIndex])

          if (e.isEnd){
            if (horizontalRef.current.swiper.activeIndex + 1 === listItems.length){
              setTimeout(() => {
               
                listDispatch({type:"activeTopic", activeTopic: horizontalRef.current.swiper.activeIndex + 2})
              }, 300);
            } else {
              // console.log("changed V")
              
              listDispatch({type:"activeStep", payload: 0 })
              setTimeout(() => {
                horizontalRef.current.swiper.slideNext(800,true)
              }, 300);
            }
           
          }else{
            // console.log("changed V")
            listDispatch({type:"activeStep", payload: e.activeIndex })
          }
        },
      }
    }

  return(
    <Swiper {...hParams} ref={horizontalRef}>
      {createSwiperColumn()}
    </Swiper>
  )
}
 
export default SimpleSwiper;