import React, { createContext, useReducer } from "react";
import LogReducer from "./LogReducer";

const initialState = {
        log:{
          id:"",
          listName:"",
          device:{},
          interactions:[]
        }

        
      }   
      


export const LogContext = createContext(initialState);
export const LogProvider = ({children}) => {
    const [state, dispatch] = useReducer(LogReducer, initialState);
    return (
      <LogContext.Provider value={[state, dispatch]}>
        {children}
      </LogContext.Provider>
    );
}; 