import React, {useContext, useState, useEffect, useRef, lazy, Suspense} from 'react';
import {createUseStyles} from 'react-jss';
import {ListContext} from "../context/ListState";
import {ExpandLess} from "@material-ui/icons";
import contentStyles from "../styles/contentStyles";
import cardStyles from "../styles/cardStyles";
import SwipeUp from "./SwipeUp"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import LazyLoad from 'react-lazy-load';
import {gsap, TimelineMax} from "gsap";
import { act } from 'react-dom/test-utils';

const classNames = require("classnames");
const useStyles = createUseStyles({
    ...cardStyles(),
    ...contentStyles(),
  })
function Card(props) {
    const classes = useStyles();
    const progRef = useRef();
    const actionRef = useRef();
    const actionStepRef = useRef();
    const topicRef = useRef();
    const topicBackgroundRef = useRef();
    const actionDivRef = useRef();
    const frontImgRef = useRef();
    const backImgRef = useRef();
    const {cardIndex, cardTopic} = props;
    const [{
        activeTopic,
        activeStep,
        lists,
        selectedList,
      },
       listDispatch,
      ] = useContext(ListContext);
      const listItems = lists[selectedList].listItems;
      let tlTopicAnimation = new TimelineMax()
      let tlActionAnimation = new TimelineMax()
      let tlActionStepAnimation = new TimelineMax()
      let tlTopicBackdropAnimation = new TimelineMax()
    
      useEffect(()=>{
        // progressAnimationTimeline();
      },[activeTopic, activeStep])
      useEffect(()=>{
        // topicAnimationTimeline();
        // backdropAnimationTimeline();
        // topicActionAnimationTimeline();
      },[activeTopic])
    
      function topicAnimationTimeline(){
        tlTopicAnimation
        .set([topicRef.current],{
            delay:0.3,
            opacity:1
        })
        .to([topicRef.current],1,{
            opacity:0.8
        })
        .to([topicRef.current],0.5,{
            delay:0.4,
            opacity:0,
        });
      }

      function progressAnimationTimeline(){
     
        //   gsap.to([progRef.current],1,{
        //       delay:0.3,
        //      value:(activeStep + 1)/(listItems[activeTopic].steps.length ) * 100 ,
        //       ease: "power4.out",
        //   })

        //   gsap.to([actionDivRef.current],1,{
        //     ease:"linear",
        // })
        //  tlActionStepAnimation
        //  .set([actionStepRef.current],{
        //      color:"rgba(255,255,255,0)",
        //      x:-10,
        //  })
        //  .to([actionStepRef.current],1,{
        //      delay:0.5,
        //      x:0,
        //      color:"rgba(255,255,255,1)",
        //  })
      }
      function topicActionAnimationTimeline(){
        tlActionAnimation
        .set([actionRef.current, progRef.current, actionStepRef.current],{
            opacity:0,
        })
        .to([actionRef.current, progRef.current, actionStepRef.current],0.5,{
            delay:1.7,
            opacity:1,
            ease:"power1.in",
        })
      }
      function backdropAnimationTimeline(){
        tlTopicBackdropAnimation
        .set([topicBackgroundRef.current],{
            opacity:1,
        })
        
        .to([topicBackgroundRef.current],1.5,{
            delay:0.5,
            opacity:0,
            ease:"power1.in",
        })
       
      }
      function frontImageLoaded(){
        // console.log("front loaded")
        //     gsap.to([frontImgRef.current],1,{
        //      opacity:1,
        //       ease: "power4.out",
        //   })
      }
      function backImageLoaded(){
        // console.log("front loaded")
        // gsap.to([backImgRef.current],1,{
        //     opacity:1,
        //      ease: "power4.out",
        //  })
    }
    function highlightUppercase(h){
      // const words = h.split(" ")
      return h
    }
      const imgClass = classNames(classes.cardImg, "swiper-zoom-container")
    return (
      <>
       {/* <SwipeUp/> */}
        <div className={classes.cardContainer}>
             
            <div className={classes.cardWrapper}>
                                       
                <div className={classes.cardActionContainerWrapper} ref={actionDivRef}>
                    {/* <div className={classes.cardActionContainer}>
                      <div className={classes.action} ref={actionRef}>{listItems[cardTopic].action}</div>
                    </div> */}
                    <div className={classes.cardImgContainer}>
                   
                        <LazyLoadImage placeholder={<div className={classes.imgPlaceholder}>Loading...</div>} afterLoad={()=> frontImageLoaded()} ref={frontImgRef} className={classes.cardImg} src={`https://d11m7jfc0ldkzr.cloudfront.net/fit-in/650x800/` + listItems[cardTopic].steps[cardIndex].media} />
                      {/* <div className="swiper-zoom-containers">
                         <img className={classes.cardImg} src={`https://d11m7jfc0ldkzr.cloudfront.net/fit-in/650x800/` + listItems[cardTopic].mediaURL[cardIndex]}/>
                      </div> */}
                    </div>
                    <div className={classes.cardActionStepContainer}>
                        <div className={classes.cardActionStepWrapper}>
                            <div className={classes.actionStep} ref={actionStepRef}>{highlightUppercase(listItems[cardTopic].steps[cardIndex].text)}</div>
                        </div>
                    </div>
                    <div className={classes.upIndicator}><ExpandLess fontSize="large"/></div>
                </div>
           </div> 
           
        
         
        </div>
        </>
    )
}

export default Card
