const sendMail = (state, action) => {
    switch (action.type) {
      
      case "message":
        return { ...state, message: action.payload };
      case "subject":
        return { ...state, subject: action.payload };
      case "impact":
        return { ...state, impact: action.payload };
      case "reportItem":
        return { ...state, reportItem: action.payload };
      case "queryParams":
        return { ...state, queryParams: action.payload };
     
       
      default:
        return state;
      }
};
      
export default sendMail