const theme = () => ({
      slide:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"0.25rem",
        marginLeft:"0.2rem",
        width:"calc(100% - 0.4rem ) !important",
        height:"calc(100% - 0.5rem)",
        borderRadius:"5px",
        overflow:"hidden !important",
        background:"rgba(33,41,49,1)",
        boxShadow:"0 4px 8px 0 rgba(0,0,0,0.2)",
        color:"#fff",
      },
   
  });

  export default theme