const theme = () => ({
    beginContainer:{
        position:"absolute",
        height:"calc(100%)",
        width:"calc(100% )",
        background:"linear-gradient(135deg, #212931,rgb(33,41,49), #212931)",
        borderRadius:"5px",
    },
    selectContainer:{
        position:"absolute",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        height:"calc(100%)",
        width:"calc(100%)",
        background:"linear-gradient(135deg, #212931,rgb(33,41,49), #212931)",
        borderRadius:"5px",
        // border:"2px dashed red",
    },
    selectBody:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"left",
        alignItems:"center",
        width:"100%",
        height:"100%",
        color:"#fff",
        fontSize:"1.3rem",
        overflow:"auto",
        // border:"2px dashed red",

    },
    beginWrapper:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        width:"calc(100% - 2rem)",
        height:"calc(100% - 7rem)",
        marginLeft:"1rem",
        background:"rgba(0,0,0,0)",
    },
    listWrapper:{
        display:"flex",
        justifyContent:"center",
        // alignItems:"left",
        flexDirection:"column",
        width:"calc(100% - 2rem)",
        height:"calc(100%)",
        // marginLeft:"1rem",
        background:"rgba(0,0,0,0)",
        // border:"2px dashed red",
    },
    endWrapper:{
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        flexDirection:"column",
        width:"calc(100% - 2rem)",
        height:"calc(100% - 7rem)",
        marginLeft:"1rem",
        marginTop:"3.5rem",
        background:"rgba(0,0,0,0)",
    },
    innerWrapper:{
        width:"calc(100%)",
        height:"calc(100%)",
    },
    beginIcon:{
        display:"flex",
        color:"#fff !important",
        padding:"0.5rem",
        borderRadius:"10px",
        background:"rgba(255,255,255,0.1) !important",
    },
    beginList:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:"0",
        // border:"2px dashed red",
    },
    beginListDiv:{
        marginTop:"1rem",
        display:"flex",
        justifyContent:"center",
        padding:"0 2rem",
        borderRadius:"10px",
        background:"rgba(0,0,0,0.1)",
        opacity:0,
    },
    beginTitleDiv:{
        opacity:0,
    },
    gatherList:{
        marginTop:"0.5rem",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        padding:"0.5rem 0",
        borderRadius:"5px",
        justifyContent:"left",
        // border:"2px dashed red",
    },
    beginTitle:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:"600",
        padding:"2rem 0.5rem 0.5rem 0.5rem",
        color:"#fff",
        textTransform:"uppercase",
    },
    listTitle:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:"600",
        fontSize:"0.9rem",
        padding:"1rem 0.5rem",
        color:"#fff",
        textTransform:"uppercase",
    },

    beginDescription:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"#999",
        padding:"0.5rem",
        borderTop:"2px solid rgba(255,255,255,0.2)",
    },
    beginBullet:{
        display:"flex",
        lineHeight:"1.8rem",
        color:"rgba(255,255,255,0.4)",
        fontSize:"1rem",
        fontWeight:"500",
        padding:"0 1rem"
    },
    bulletNumberColor:{
        //textAlign:"right",
        color:"#377771",
        fontSize:"1.1rem",
        //width:"1rem",
       marginRight:"0.25rem",
    }, 
    beginDetails:{
        fontSize:"1rem",
        //color:"#fff",
    },
   
    beginButtonContainer:{
        width:"100%",
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        flexDirection:"row",
    },

    beginButton:{
        marginTop:"2rem",
        //height:"4rem",
        //width:"8rem",
        padding:"1rem 2rem",
        fontSize:"1rem",
        background:"rgba(0,0,0,0)",
       border:"1px solid rgba(255,255,255,0.3)",
        borderRadius:"5px",
        color:"#fff",
       // boxShadow:"0px 10px 15px rgba(0,0,0,0.2)",
        textTransform:"uppercase",
        outline:"none",
        opacity:0,
    },
    beginBackButton:{
        marginTop:"2rem",
        //height:"4rem",
        //width:"8rem",
        padding:"0.8rem 1.8rem",
        fontSize:"0.8rem",
        background:"rgba(255,255,255,0.05)",
       border:"0",
        borderRadius:"5px",
        color:"#fff",
       // boxShadow:"0px 10px 15px rgba(0,0,0,0.2)",
        textTransform:"uppercase",
        outline:"none",
        opacity:0,
    },
    endButton:{
        height:"4rem",
        width:"8rem",
        fontSize:"1.4rem",
        background:"rgba(0,0,0,0)",
       border:"1px solid rgba(255,255,255,0.3)",
        borderRadius:"5px",
        color:"#fff",
       // boxShadow:"0px 10px 15px rgba(0,0,0,0.2)",
        textTransform:"uppercase",
        outline:"none",
        opacity:0,
    },
    beginLoadingContainer:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        height:"10rem",
        alignItems:"center",
    },
    beginLoading:{
        position:"absolute",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        lineHeight:"4rem",
        width:"100%",
        color:"#fff",
        fontSize:"1.2rem",
    },
    beginLoader:{
        color:"#fff !important",
        width:"3rem !important",
        height:"3rem !important",
    },
    beginRow:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
    },
    landingContainer:{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        // border:"2px dashed red",
    },
    landingScanDiv:{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        color:"#fff",
        // border:"2px dashed red",
    },
    landingOrDiv:{
        width:"100%",
        height:"1rem",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        color:"#fff",
        // border:"2px dashed red",
    },
    landingLoginDiv:{
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        color:"#fff",
        // border:"2px dashed red",
    },
    or:{
        height:"1rem",
        color:"#fff",
        fontSize:"1rem",
        // border:"2px dashed red",
    },
    loginDiv:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        height:"calc(100% - 5rem)",
        width:"calc(100% - 5rem)",
        // background:"rgba(0,0,0,0.2)",
    },
    scanDiv:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        height:"calc(100% - 5rem)",
        width:"calc(100% - 5rem)",
        // background:"rgba(0,0,0,0.2)",
    },
    hiddenButton:{
        background:"rgba(0,0,0,0)",
        fontSize:"1rem",
        color:"#aaa",
        border:"0",
        margin:"0.5rem",
    },
    listUl: {
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        listStyle: "none",
        padding:"1px 0",
        borderTop:"2px solid rgba(255,255,255,0.1)",
        borderRadius:"10px",
        width:"100%",
        background:"rgba(0,0,0,0.2)"
    },
    listLi:{
        display:"flex",
        justifyContent:"center",
        // fontSize:"0.3rem",
        borderTop:"1px solid rgba(0,0,0,0.1)",
        borderBottom:"1px solid rgba(0,0,0,0.1)",
        margin:"0 1rem",
    },
    reportUl: {
       //position:"absolute",
       top:0,
       left:0,
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-start",
        width:"100%",
        height:"100%",
        overflow:"auto",
        //zIndex:300,
        // border:"2px dashed red",
    },
    reportWrapper:{
        // display:"flex",
        width:"100%",
        height:"100%",
        // border:"2px dashed red",
    },
    reportLi: {
      // height:"6rem",
        width:"calc(100% - 1rem - 4px)",
        //margin:"0.2rem 0.5rem",
        border:"2px solid rgba(255,255,255,0.2)",
        borderRadius:"10px",
        fontSize:"0.8rem",
        background:"rgba(255,255,255,0.2)",
        margin:"1rem 0.5rem",
    },
    reportLiTitle:{
        display:"flex",
        justifyContent:"left",
        alignItems:"center",
        padding:"0.1rem 0.75rem",
        width:"calc(100% - 1.5rem)",
        height:"3rem",
        background:"rgba(0,0,0,0.2)",
        borderRadius:"10px 10px 0 0",
      
    },
    reportLiBody:{
        padding:"0.25rem"
    },
    reportItem:{
        padding:"0.25rem 0",
        width:"100%",
        height:"100%",
        textAlign:"center",
        fontSize:"1rem",
    },
    reportLiTitleWorkaround:{
        width:"100%",
        height:"100%",
        background:"yellow",
        color:"black",
    },
    locationTitle:{
        //position:"absolute",
        width:"100%",
        fontSize:"2.1rem",
        background:"rgba(0,0,0,0.2)",
        fontWeight:"900",
        color:"rgba(255,255,255,0.25)",
        display:"flex",
        padding:"0.25rem 1rem 0.1rem 1rem",
        justifyContent:"left",
        borderBottom:"2px solid rgba(255,255,255,0.1)",
    },
    selectOptions:{
        // border:"2px dashed red",
        color:"rgba(255,255,255,0.5)",
        fontSize:"0.9rem",
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        height:"2rem",
        textTransform:"uppercase",
    },
    selectFooter:{
        zIndex:"3000 !important",
        
    },
    report:{
        background:"rgba(255,255,255,1) important!",
        color:"#fff important!",
    },
    bottomNavigation:{
        background:"#1a2127 !important",
        //  fontSize:"0.5rem !important",
        borderTop:"2px solid rgba(255,255,255,0.2)",
        padding:"0.5rem",
        zIndex:"3000 !important",
    },
    bottomNavAction:{
        color:"rgba(255,255,255,0.5) !important",
    },
    bottomNavSelected:{
        color:"#fff !important",
    },
    accordion:{
        background:"#3e4348 !important",
        color:"#fff !important",
        width:"100%",
        fontSize:"1.2",
        // border:"2px dashed red",
        // height:0
    }
  });

  export default theme