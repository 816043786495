import React, {useState} from 'react'
import featureStyles from "../styles/featureStyles";
import {createUseStyles} from 'react-jss';
import {ReportProblem, ThumbUp, NotificationImportant, Comment} from "@material-ui/icons"

const useStyles = createUseStyles({
    ...featureStyles(),
})

function InfoCard({infoOpen, myImpact,openInfoFn}) {
    //const [checked,setChecked] = useState(null)
    const classes = useStyles();

    async function handleOptionChange(e){
        await myImpact(e.target.value)
        openInfoFn(false)
    }
    
    return (
        <div className={classes.impactContainer}>
            <div className={classes.impactTitle}>How urgent is this report?</div>
            <div className={classes.impactWrapper}>
                <div className={classes.impactOption}>
                    <input className={classes.impactInput}type="radio" id="comment" name="impact" value="Comment" onClick={handleOptionChange} />
                    <label className={classes.impactComment} htmlFor="comment">
                        <div className={classes.impactCenter}> <Comment/>&nbsp;&nbsp;Comment</div>
                        <div className={classes.impactDetails}>"I have a comment or suggestion to add.  There's no issue, per se."</div>
                    </label>
                </div>
                <div className={classes.impactOption}>
                    <input className={classes.impactInput}type="radio" id="resolved" name="impact" value="Resolved" onClick={handleOptionChange} />
                    <label className={classes.impactResolved} htmlFor="resolved">
                        <div className={classes.impactCenter}> <ThumbUp/>&nbsp;&nbsp;Resolved</div>
                        <div className={classes.impactDetails}>"The issue has been fixed. I'm just letting everyone know it happened."</div>

                    </label>
                </div>
                <div className={classes.impactOption}>
                    <input className={classes.impactInput} type="radio" id="workaround" name="impact" value="Workaround" onClick={handleOptionChange} />
                    <label className={classes.impactWarning} htmlFor="workaround">
                        <div className={classes.impactCenter}> <ReportProblem/> &nbsp;&nbsp;Workaround</div>
                        <div className={classes.impactDetails}>"The issue still exists, but we can move forward without an immediate fix."</div>
                    </label>
                  
                </div>
                <div className={classes.impactOption}>
                    <input className={classes.impactInput}type="radio" id="urgent" name="impact"value="Urgent" onChange={handleOptionChange} />
                    <label className={classes.impactAlert} htmlFor="urgent">
                        <div className={classes.impactCenter}><NotificationImportant/> &nbsp;&nbsp;Urgent</div>
                        <div className={classes.impactDetails}>"The issue still exists and we can't move forward without a fix. Please send help ASAP!"</div>
                    </label>
                </div>
            </div>
           
        </div>
    )
}

export default InfoCard
