const theme = () => ({
   navbar:{
       position:"fixed",
       left:"1rem",
       margin:"0",
       display:"flex",
       justifyContent:"space-between",
       alignItems:"center",
       width:"calc(4rem)",
       height:"4.25rem",
       fontSize:"2rem",
       zIndex:"4",
     //   border:"2px dashed red",
   },
   navButton:{
     display:"flex !important",
     justifyContent:"center !important",
     alignItems:"center !important",
     position:"absolute",
     margin:"0 !important",
     padding:"0 !important",
     width:"2.5rem !important",
     height:"2.5rem !important",
     color:"rgba(156, 246, 246,0.9) ",
    //  border:"2px dashed red",
   },
   navButtonBlockLeft:{
    marginTop:"0.25rem",
    marginLeft:"0.75rem",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"4.75rem",
    height:"2.5rem",
    zIndex:"1",
    // background:"#fff",
    //border:"2px dashed red",
   },
   navButtonBlockRight:{
     marginTop:"1rem",
     marginRight:"1rem",
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     width:"4.75rem",
     height:"2.5rem",
     zIndex:"-1",
    //  background:"#fff",
    // border:"2px dashed red",
   },
   navMid:{
    marginTop:"0.75rem",

     display:"flex",
      justifyContent:"center",
      alignItems:"center",
      width:"calc(100%)",
      height:"2.75rem",
      zIndex:"-1",
      // border:"2px dashed red",
   },
   drawer:{
       backgroundColor:"rgb(200,200,200,0.9) !important",
       overflow:"visible !important",
       color:"rgba(211, 208, 203,1) "
       //border:"2px dashed red",
   },
   drawerHeader:{
        //padding:"1rem 0.5rem",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        //border:"2px dashed red",
        height:"3.5rem",
        width:"calc(100%)",
        background:"#495a69",
        // boxShadow:"-0.25rem 5px 10px rgba(45, 50, 51,0.6)",
        borderRadius:"0 0 2px 0",
        // borderBottom:"1px solid rgba(211, 208, 203,0.5)",
        // borderRight:"1px solid rgba(211, 208, 203,0.25)",
        paddingRight:"1rem",
       //width:"8rem",
   },
   drawerHeaderBlock:{
        color:"rgba(211, 208, 203,1) ",
        padding:"0.5rem 0rem 0.5rem 1rem",
        //border:"2px dashed red",
   },
   
   profileIcon:{
        background:"#32908F  !important",
        color:"rgba(211, 208, 203,1)",
        fontSize:"3rem !important",
        //border:"2px dashed red !important",
   },
   drawerProfileName:{
       fontSize:"1.3rem", 
       fontWeight:"600",
   },
   drawerProfileEmail:{
       fontSize:"0.8rem",
       fontWeight:"300",
   },
});

export default theme