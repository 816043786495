const checkParam = (state, action) => {
    switch (action.type) {
        case "setId":
         return { ...state, device: action.payload };
        case "setCategory":
         return { ...state, device: action.payload };
        case "setListName":
         return { ...state, device: action.payload };
        case "setBuilding":
         return { ...state, device: action.payload };
        case "setRoom":
         return { ...state, device: action.payload };
        case "setItem":
         return { ...state, device: action.payload };
        default:
          return state;
        }
};
      
export default checkParam