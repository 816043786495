/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "how2listdb-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "listLogsTable-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "listLogsData-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "listLogsTable1-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "listLogsTable3-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "listreportstable01-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "how2restapi",
            "endpoint": "https://z9k4z4wm5m.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "listLogsApi3",
            "endpoint": "https://9hxb0qpds1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "listreportsapi01",
            "endpoint": "https://zuszpx8q9j.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vky36nswxzfqvnlnyuaffuq4zu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:a770ebe3-7856-409f-ace7-07f6c761d8ba",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_31foX5zCo",
    "aws_user_pools_web_client_id": "7chk9gc0qipm9q65emk4tubd4g",
    "oauth": {}
};


export default awsmobile;
