import React, { useContext, useEffect, useRef, useState} from 'react';
import globalStyles from "../styles/globalStyles";
import contentStyles from "../styles/contentStyles";
import swiperStyles from "../styles/swiperStyles";
import {ListContext} from "../context/ListState";
import {SendContext} from "../context/SendState";
import {LogContext} from "../context/LogState";

import {useTransition, animated} from 'react-spring';
import {gsap, TimelineMax} from "gsap";
import {createUseStyles} from 'react-jss';
import Swipe from "../components/Swipe";
import Messenger from "../components/Messenger"
import InfoCard from "../components/InfoCard"
import {Info, Chat, CallReceived, Close} from "@material-ui/icons"
import Lambda from 'aws-sdk/clients/lambda';
import { API, Auth, Hub } from"aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import "../styles/App.css"

const useStyles = createUseStyles({
    ...globalStyles(),
    ...contentStyles(),
    ...swiperStyles(),
})

function Content() {
    const classes = useStyles();
    const topicTitleRef = useRef();
    const topicTextContainerRef = useRef();
    const progLRef = useRef();
    const progRRef = useRef();
    const topicStepRef = useRef();
    const messageSpaceRef = useRef();
    const infoSpaceRef = useRef();
    const infoBackRef = useRef();
    const footerRef = useRef();
    const headerRef = useRef();
    const bodyRef = useRef();
    const contentRef = useRef();
    let tlTopicTitleAnimation = new TimelineMax();
    let tlMsgSpaceAnimation = new TimelineMax();
    let tlInfoSpaceAnimation = new TimelineMax();
    let tlEntranceAnimation = new TimelineMax();
    let tlFooterAnimation = new TimelineMax();
    let tlHeaderAnimation = new TimelineMax();
    const [{
        swipeDirection,
        activeTopic,
        activeStep,
        lists,
        selectedList,
      },
      //listDispatch,
      ] = useContext(ListContext);
    const [{
       message,
       subject,
       queryParams,
       //impact,
       reportItem,
      },
      //SendDispatch,
      ] = useContext(SendContext);
      const [{
        log
       },
        logDispatch,
       ] = useContext(LogContext);

      const listItems = lists[selectedList].listItems;
      const [messageOpen,setMessageOpen] = useState(false);
      const [infoOpen,setInfoOpen] = useState(false);
      const [details, setDetails]=useState(null)  
      const [impact, setImpact]=useState(null)  
      useEffect(()=>{
        // console.log(lists[selectedList].listItems)
      },[])
      useEffect(()=>{
        messageOpenTimeline()
      },[messageOpen])
      useEffect(()=>{
       infoOpenTimeline()
      },[infoOpen])
      useEffect(()=>{
       entranceTimeline()
      },[])
      useEffect(()=>{
        if (impact !== null){
          sendMyEmail()
        }
       setImpact(null)
      },[impact])
      useEffect(()=>{
        gsap.set([topicStepRef.current],{
          marginLeft:`0.5rem`,
        })
        gsap.set([topicTextContainerRef.current],{
          width:(100 * listItems.length) + "%", 
        })
      },[])

      useEffect(()=>{
        gsap.to([topicStepRef.current],0.75,{
            x:activeTopic * (100/listItems.length) + "%",
        })
        gsap.to([topicTextContainerRef.current],0.75,{
            x:activeTopic * (-100/listItems.length) + "%",
            ease:"back.out(0.4)",
        })
      },[activeTopic])

      useEffect(()=>{
        progressAnimationTimeline()
      },[activeTopic,activeStep])

      function entranceTimeline(){
          gsap.to([contentRef.current],1,{
            opacity:1,
          })
          tlEntranceAnimation
          .set([footerRef.current, headerRef.current, bodyRef.current],{
         scaleY:2,
        })
        .to([footerRef.current, headerRef.current, bodyRef.current],0.5,{
           scale:1,
           opacity:1,//
            ease:"power3.out"
        })
          tlFooterAnimation
          .set([footerRef.current],{
        
         y:100 + "%"
        })
        .to([footerRef.current],0.5,{
           delay:0.25,
           y:0,
            ease:"power3.out"
        })
          tlHeaderAnimation
          .set([headerRef.current],{
            y:-100 + "%",
        })
        .to([headerRef.current],0.5,{
           delay:0.25,
           y:0,
            ease:"power3.out"
        })
      }

      function infoOpenTimeline(){
          if (infoOpen === true){
            tlInfoSpaceAnimation
                .set([infoBackRef.current],{
                    zIndex:4,
                })
                .to([infoBackRef.current],0.35,{
                    opacity:1,
                    ease:"power3.out"
                })
          } else {
            tlInfoSpaceAnimation
            .to([infoBackRef.current],0.35,{
                delay:0.2,
                opacity:0,
                ease:"power3.out"
            })
            .set([infoBackRef.current],{
                zIndex:-1,
            })
          }
      }
    
      function messageOpenTimeline(){
          if (messageOpen === true){
            tlMsgSpaceAnimation
                .set([messageSpaceRef.current],{
                    zIndex:4,
                })
                .to([messageSpaceRef.current],0.35,{
                    opacity:1,
                    ease:"power3.out"
                })
          } else {
            tlMsgSpaceAnimation
            .to([messageSpaceRef.current],0.35,{
                delay:0.2,
                opacity:0,
                ease:"power3.out"
            })
            .set([messageSpaceRef.current],{
                zIndex:-1,
            })
          }
      }
    
      const toggleMessageOpen = (val) =>{
        setMessageOpen(val)
      }
      const toggleInfoOpen = () =>{
        setInfoOpen(prevState => !prevState)
      }
      function progressAnimationTimeline(){
          gsap.to([progLRef.current, progRRef.current],1,{
              delay:0.2,
              y:(activeStep)/(listItems[activeTopic].steps.length  - 1) * 100 + "%",
              ease: "power4.out",
          })
      }
      function myMessage(val){
        console.log(val)
        setDetails(val)
      }
      async function myImpact(i){
        await setImpact(i)
        
      }
      async function sendMyEmail(val){
        const myInit ={
          header:{
            "Access-Control-Allow-Origin" :"*",
            "Access-Control-Allow-Headers":"*",
            "Access-Control-Allow-Methods":"*",
          },
          body:{
            id: uuidv4(),
            "building":queryParams.blg,//param.blg,
            "room":queryParams.rm,//param.rm,
            "list":lists[selectedList].subject,//param.cat,
            "subject":lists[selectedList].listItems[activeTopic].topic,//checklist.listItems[activeStep2].item,
            "timestamp":Date.now(),
            "reportItem":details,// val,
            "impact":impact,//impact
            "status":"new",
          }
        }
        try{
          const res = await API.post("listreportsapi01","/reports", myInit)
          console.log("report post succeeded", res.data);
        }catch(err){
          console.log("report post error", err);
        }
         Auth.currentCredentials()
         .then(credentials => {
           const lambda = new Lambda({
             credentials: Auth.essentialCredentials(credentials),
             region:"us-east-1"
           });
           const params = {
             FunctionName: 'sendemailfunction',
             InvokeArgs: JSON.stringify({
              "building":queryParams.blg,//param.blg,
              "room":queryParams.rm,//param.rm,
              "list":lists[selectedList].subject,//param.cat,
              "subject":lists[selectedList].listItems[activeTopic].topic,//checklist.listItems[activeStep2].item,
              "timestamp":Date.now(),
              "reportItem":details,// val,
              "impact":impact,//impact
             }),
           }
         
           lambda.invokeAsync(params, function(err,data){
             if (err) console.log(err, err.stack); // an error occurred
             else     console.log(data);           // successful response
           });
      
           
         })
        
       }
    return (
        <div className={classes.topLevelContainer}>
            <div className={classes.contentContainer} ref={contentRef}>
                <div className={classes.header} ref={headerRef}>
                    <div className={classes.topicProgressContainer}>
                     
                        <div className={classes.topicProgressBarActive}  ref={topicStepRef}>
                            {listItems.map((label, index) => {
                                return (
                                    <div key={index} className={(index === 0)?  classes.activeTopic : classes.hiddenTopic }></div>
                                );
                            })}
                        </div>
                           <div className={classes.topicProgressBar}>
                            {listItems.map((label, index) => {
                                return ( <div key={index} className={classes.topicStep}></div>);
                            })}
                        </div>
                    </div> 
                    <div className={classes.topicTextContainer} ref={topicTextContainerRef}>
                                {listItems.map((item, index) => {
                                return (
                                    <div key={index} className={classes.topic} ref={topicTitleRef}>{item.topic}</div>
                                );
                            })}
                    </div>
                </div>
                <div className={classes.bodyWrapper} ref={bodyRef}>
                    <div className={classes.stepProgressContainerLeft}>
                            <div className={classes.stepProgressBar} ref={progLRef}></div>
                    </div>
                    <div className={classes.body}>
                      <div className={classes.swipeSpace}> 
                          <Swipe />
                      </div>
                    </div>
                    <div className={classes.stepProgressContainerRight}>
                            <div className={classes.stepProgressBar} ref={progRRef}></div>
                    </div>
                    <div className={classes.messageSpace} ref={messageSpaceRef}><Messenger messageOpen={messageOpen} openFn={()=>toggleMessageOpen()} impactOpen={()=>toggleInfoOpen()}myMessage={myMessage}/></div>
                    <div className={classes.infoBackdrop} ref={infoBackRef}>
                        <div className={classes.infoSpace} ref={infoSpaceRef}>
                            <div className={classes.closeInfo}><button className={classes.invisibleButton} onClick={()=>toggleInfoOpen()}><Close fontSize="large" color="primary"/></button></div>
                            <InfoCard infoOpen={infoOpen} openInfoFn={toggleInfoOpen} myImpact={myImpact}/>
                        </div> 
                    </div>
                </div>
                <div className={classes.footer} ref={footerRef}>
                    <div >
                        <button className={classes.invisibleButton} onClick={()=>toggleMessageOpen((prev)=> !prev)}><Chat className={classes.infoIcon}></Chat></button>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Content
