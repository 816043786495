import React, {useContext, useState,useEffect} from 'react'
import {ListContext} from "../context/ListState";
import {ReportContext} from "../context/ReportState";
import {SendContext} from "../context/SendState";
import {DataGrid} from '@material-ui/data-grid';
import {createUseStyles} from 'react-jss';
import globalStyles from "../styles/globalStyles";
import beginStyles from "../styles/beginStyles";
import {Accordion, AccordionDetails,AccordionSummary} from "@material-ui/core"
import {ExpandMore} from "@material-ui/icons"
const useStyles = createUseStyles({
    ...globalStyles(),
    ...beginStyles(),
  })
function SelectReports() {
    const classes = useStyles();
    const [rows,setRows]=useState([ { id: 1, time: '', type:"", impact:"", report:"" },]);

    const [{
        activeTopic,
        lists,
        selectedList
    },
   listDispatch,
    ] = useContext(ListContext);
    const [{
        queryParams
     },
    sendDispatch,
     ] = useContext(SendContext);
     const [{
        reports,
     },
    reportDispatch,
     ] = useContext(ReportContext);
     const columns = [
        { "field": 'time', "headerName": 'Date', "width": 170 },
        { field: 'list', headerName: 'List', width: 150 },
        { field: 'impact', headerName: 'Impact', width: 150 },
        { field: 'report', headerName: 'Report', width: 1000 },
    ]
    useEffect(()=>{
        if (mapReports()){
            setRows(mapReports())
            // console.log("map reports",mapReports())
        }
       
    
    },[reports])
    function mapReports(){
      
        if (reports.length > 0){
            const filteredReports = reports.filter((f)=>f.impact === "Workaround" || f.impact === "Urgent");

           const res = filteredReports.map((item,index)=>{
                   let d = new Date(item.timestamp)
                return (
                {
                    "id":index + 1,
                    "time": d.toLocaleTimeString(), 
                    "list":item.list, 
                    "impact":item.impact, 
                    "report":item.reportItem
                }
                )
            
            })
            // console.log("rows",rows)
            // console.log("row function",res)
            if (res.length > 0){
                return res
            } else {
               
                return res
            }
        } else {
            return
        }
    }
    return (
        <div>
           <div className={classes.listTitle}> Recent issue reports from {lists[selectedList].location.building.toUpperCase()}-{lists[selectedList].location.room.toUpperCase()}</div>
           <div className={classes.reportWrapper}>
            <div className={classes.reportUl}
            >
                {reports.map((item,index)=>{
                     let d = new Date(item.timestamp)
                          return (
                              <div key={index} className={classes.reportLi}>
                                  <div className={classes.reportLiTitle}>
                                     
                                      <Accordion className={classes.accordion}  >
                                          <AccordionSummary  expandIcon={<ExpandMore />} > {item.impact}&nbsp;&nbsp;<span>{d.toLocaleDateString()} </span></AccordionSummary>
                                          <AccordionDetails>
                                                List: {item.list}
                                            <br/>
                                            Topic: {item.subject}
                                            <br/>
                                            Report:
                                          </AccordionDetails>
                                      </Accordion>
                                      </div>
                                  <div className={classes.reportLiBody}>
                                    
                                    <div className={classes.reportItem}>{item.reportItem}</div>
                                  </div>
                                
                                </div>
                          )
                })}
            </div>  
            </div>
        </div>
    )
}

export default SelectReports
