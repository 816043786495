const theme = () => ({
    impactContainer:{
        padding:"1rem",
        display:"flex",
        
        //width:"calc(100%)",
        height:"calc(100% - 5rem)",
        flexDirection:"column",
        justifyContent:"space-between",
        //border:"2px dashed red",
    },
    impactWrapper:{
        height:"100%",

        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        // border:"2px dashed red",
    },
    impactTitle:{
        fontSize:"1.1rem",
        paddingBottom:"0.25rem",
        borderBottom:"2px solid rgba(255,255,255,0.2)",
    },
    impactOption:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        width:"calc(100% - 8px)",
        // border:"2px solid rgba(255,255,255,0.5)",
        borderRadius:"10px",
        //background:"rgba(255,255,255,0.1)",
        padding:"0.25rem",
        outline:"none",
    },
    impactDetails:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        fontSize:"0.8rem",
        margin:"0.25rem",
        padding:"0 1rem",
        color:"#ccc",
        lineHeight:"1rem"
        //border:"2px dashed red",
    },
    impactAlert:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        color:"red",
        textTransform:"uppercase",
        background:"rgba(0,0,0,0.25)",
        borderRadius:"5px",
    },
    impactWarning:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        color:"yellow",
        textTransform:"uppercase",
        background:"rgba(0,0,0,0.25)",
        borderRadius:"5px",
    },
    impactResolved:{
        
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        color:"green",
        textTransform:"uppercase",
        background:"rgba(0,0,0,0.25)",
        borderRadius:"5px",
    },
    impactComment:{
        
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        //color:"green",
        textTransform:"uppercase",
        background:"rgba(0,0,0,0.25)",
        borderRadius:"5px",
    },
    impactCenter:{
        marginTop:"0.2rem",
        width:"calc(100% - 0.4rem)",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        borderRadius:"5px",
       background:"rgba(0,0,0,0.25)",

    },
    impactInput:{
        border:"0",
    },
  
  });

  export default theme