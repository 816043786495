const theme = () => ({
    topLevelContainer:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column",
        height:"calc(100%)",
        width:"calc(100%)",
              // border:"2px dashed red",
      },

      contentContainer:{
        position:"absolute",
        top:"0",
        left:"0",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        height:"calc(100%)",
        width:"calc(100% )",
        background:"linear-gradient(90deg, #55697b 0.1rem,#495a69 1rem, #495a69 calc(100% - 1.2rem), #3d4b57)",
        borderRadius:"5px",
        overflow:"hidden",
      },
      
      header:{
        padding:"0",
        marginLeft:"0.5rem",
        width:"calc(100% - 1rem)",
        zIndex:"3",
        opacity:0,
        overflow:"hidden",
        // border:"2px dashed red",
      },

      headerFillBar:{
        width:"100%",
        zIndex:"1",
      },

      bodyWrapper:{
        position:"relative",
        display:"flex",
        // flexDirection:"column",
        justifyContent:"space-between",
        width:"calc(100%)",
        height:"calc(100%)",
       overflow:"hidden !important",
      
      //  borderTop:"1px solid rgba(0,0,0,0.25)",
        // borderBottom:"1px solid rgba(0,0,0,0.25)",
       opacity:0,
      //  border:"2px dashed red",
      },

      body:{
        // position:"absolute",
        display:"flex",
        borderTop:"1px solid rgba(0,0,0,0.15)",
        borderBottom:"1px solid rgba(0,0,0,0.15)",
        width:"calc(100% - 6px)",
        height:"calc(100% - 6px)",
        overflow:"hidden",
        boxShadow:"inset 0px 2px 5px rgba(0,0,0,0.15)",
        zIndex:"3",
        borderRadius:"5px",
        overflow:"hidden",
      },

      footer:{
        display:"flex",
        //flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        marginLeft:"0.5rem",
        width:"calc(100% - 1rem)",
        height:"3.75rem",
        opacity:0,
        //background:"linear-gradient(90deg, #55697b 0.1rem,#495a69 1rem, #495a69 calc(100% - 1.2rem), #3d4b57)",
        // transform:"translate3d(0,500px,0)",
        zIndex:"0",
      },

      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:"rgba(255,255,255,1)",
        width:"100%",
      },
      backdrop:{
        position:"absolute",
        top:"0",
        left:"0",
        width:"100%",
        height:"100%",
        zIndex:"1",
        background:"rgba(0,0,0,0.5)",
      },
      button:{
       color:"#fff",
        padding:"0.75rem 1.5rem",
        borderRadius:"10px",
      },
      invisibleButton:{
        background:"transparent",
        border:"none !important",
        outline:"none",
        "&:selected":{
          border:"none !important",
        },
        "&:active":{
          border:"none !important",
        },
      },
  });

  export default theme