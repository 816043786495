const theme = () => ({
    swipeUpContainer:{
        position:"absolute",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        height:"100%",
        // background:"rgba(255,255,255,0.8)",
        // border:"2px dashed red",
        zIndex:400,
    },
    swipeUpWrapper:{
        // position:"absolute",
        display:"flex",
        justifyContent:"center",
        alignItems:"flex-end",
        background:"rgba(0,0,0,0.7)",
        border:"1px solid rgba(255,255,255,0.5)",
        width:"6rem",
        height:"calc(20rem)",
        borderRadius:"60px",
    },
    swipeUpCircle:{
        // position:"absolute",
        // bottom:0,
        background:"rgba(255,255,255,0.9)",
        borderRadius:"10rem",
        width:"5rem",
        height:"6rem",
    }
   
});

export default theme