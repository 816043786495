import React, {useEffect} from "react"
import './styles/App.css';
import {CheckParamProvider} from"./context/CheckParamState";
import {ListProvider} from "./context/ListState"
import {ReportProvider} from "./context/ReportState"
import {SendProvider} from "./context/SendState"
import {LogProvider} from "./context/LogState"
import ChecklistApp from "./pages/ChecklistApp"


function App() {
  // const element = document.querySelector('div');

  // element.addEventListener('touchstart', (e) => {
  //     // prevent swipe to navigate gesture
  //     e.preventDefault();
  // });
  useEffect(()=>{
    preventIosTouch()
  },[])

  function preventIosTouch(){
    document.addEventListener(
      'gesturestart', (e) => e.preventDefault()
  );
  }
  return (
    <CheckParamProvider>
      <ListProvider>
      <ReportProvider>
      <SendProvider>
      <LogProvider>
      <ChecklistApp/>
      </LogProvider>
      </SendProvider>
      </ReportProvider>
      </ListProvider>
    </CheckParamProvider>
   
  );
}

export default App;
