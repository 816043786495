import React, { createContext, useReducer } from "react";
import SendReducer from "./SendReducer";

const initialState = {
        message:"",
        queryParams:{
        },
      
        subject:"",
        reportItem:"",
        impact:"",
      }   
      


export const SendContext = createContext(initialState);
export const SendProvider = ({children}) => {
    const [state, dispatch] = useReducer(SendReducer, initialState);
    return (
        <SendContext.Provider value={[state, dispatch]}>
        {children}
      </SendContext.Provider>
    );
}; 