import React, { createContext, useReducer } from "react";
import CheckParamReducer from "./CheckParamReducer";

const initialState = {
    listId:null,
    category: null,
    listName: null,
    building: null,
    room: null,
    item: null,
}

export const CheckParamContext = createContext(initialState);
export const CheckParamProvider = ({children}) => {
    const [state, dispatch] = useReducer(CheckParamReducer, initialState);
    return (
        <CheckParamContext.Provider value={[state, dispatch]}>
        {children}
      </CheckParamContext.Provider>
    );
};