import React, {useContext, useEffect, useState} from 'react';
import {ListContext} from "../context/ListState";
import {ReportContext} from "../context/ReportState";
import {LogContext} from "../context/LogState";
import {SendContext} from "../context/SendState";

import Loading from "./Loading";
import Landing from "./Landing";
import Content from "./Content";
import Select from "./Select"

import SwipeUp from "../components/SwipeUp";
import Navbar from "../components/Navbar";
import BeginCard from '../components/BeginCard';
import ListSelect from '../components/ListSelect';

import EndCard from "../components/EndCard";
import "../styles/App.css"
import { API, Auth, Hub } from"aws-amplify";
import { v4 as uuidv4 } from 'uuid';
import Bowser from "bowser"
const queryString = require('query-string');
const address = require('address');
// const Bowser = require("bowser")
//import globalStyles from "../styles/globalStyles"
//import {createUseStyles} from 'react-jss'
// const useStyles = createUseStyles({
//     ...globalStyles()
// })

function ChecklistApp() {
   // const classes = useStyles();
    const [{
        activeTopic,
        lists,
        selectedList
    },
   listDispatch,
    ] = useContext(ListContext);
    const [{
        reports,
    },
   reportDispatch,
    ] = useContext(ReportContext);
    const [{
       log
    },
   logDispatch,
    ] = useContext(LogContext);
    const [{
       queryParams
    },
   sendDispatch,
    ] = useContext(SendContext);
    const [selected, setSelected]=useState(false);
    const [isLoading, setIsLoading]=useState(true);
    // const listItems = lists[selectedList].listItems;
    useEffect(()=>{
     getChecklist()
     getParams()
    },[])
    useEffect(()=>{
      // console.log("interaction",log.interactions[log.interactions.length -1])
    },[log])
    useEffect(()=>{
     // console.log("lists",lists)
    },[lists])

    async function getParams(){
      const parsed = await queryString.parse(window.location.search)
      const browser = Bowser.getParser(window.navigator.userAgent);
      const network = address(function (err, addrs) {
        return addrs
      });
      const device = {
        browser: browser.parsedResult.browser,
        engine: browser.parsedResult.engine,
        os: browser.parsedResult.os,
        platform: browser.parsedResult.platform,
        network: network ? network:"unknown",
      }
      // if (parsed !== {}){
      //  console.log("parsed",parsed)
      sendDispatch({type:"queryParams",payload:parsed})
      logDispatch({type:"logBrowser", payload:device})
      // }
     
     
    }
    async function getChecklist (){
      
      const myGet ={
         // headers:{
            // "Access-Control-Allow-Origin":"*",
            // "Access-Control-Allow-Headers":"*",
         // "Access-Control-Allow-Methods":"*",
         // },
        queryStringParameters: {  
            //category:'room checks',
        },
      }
         try {
            const res = await API.get("how2restapi","/list", myGet)
            const parsed = await queryString.parse(window.location.search)
            const result = await res.data.filter(d => d.organization.toLowerCase().includes(parsed.org) && d.location.building === parsed.blg && d.location.room === parsed.rm)
            // console.log("get result", result.length)
            if (result.length > 0){
               listDispatch({type:"setLists", payload:result})
               setIsLoading(true)
            } else{
              setIsLoading(false)
            }
          }
          catch (err){
           console.log("this is an error", err);
         }

         try {
          const res = await API.get("listreportsapi01","/reports", myGet)
          const parsed = await queryString.parse(window.location.search)
          const result = await res.data.filter(d => d.building === parsed.blg && d.room === parsed.rm)
          // console.log("records result", result)
          reportDispatch({type:"setReportList", payload:result})
         }
         catch (err){
          console.log("this is an error111", err);
        }
      }
    
    function toggleSelected(val){
      // console.log("setval", val)//
        setSelected(val)
      }
  
      async  function postChecklist (){
        const myInit = { // OPTIONAL
           headers: {
           
           },
           body:{
            id: uuidv4(),
            
              "categories": [
                "Sloan AV Checklists"
              ],
              "description": "A more detailed list of classroom checks.",
              "listItems": [
                {
                  "action": "",
                  "path": [
                    {
                      "icon": "",
                      "text": ""
                    },
                    {
                      "icon": "",
                      "text": ""
                    }
                  ],
                  "steps": [
                    {
                      "media": "e51-149-classroom.jpg",
                      "text": "Report any issues experienced during class below"
                    }
                  ],
                  "topic": ""
                }
              ],
              "location": {
                "building": "e52",
                "room": "724"
              },
              "organization": "Sloan",
              "subject": "Samberg AV Checklist"
            
          
            },
             // OPTIONAL
             response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
             queryStringParameters: {  // OPTIONAL
               
             },
         };
            try {
           const res = await API.post("how2restapi","/list", myInit)
             console.log("list post succeeded", res.data);
             
            } catch (err){
              console.log("list post error", err);
            }
           
         }
      // console.log("list length",lists.length)
            if (lists.length > 1){
              if (activeTopic < 0){
                return  (
                <>
                    <Navbar/>
                    <Select selected={selected} setSelected={toggleSelected}/>
                    {/* <ListSelect selected={selected} setSelected={toggleSelected}/> */}
                </>)
              } else if ( activeTopic > lists[selectedList].listItems.length -1){
                return (
                <>
                    <Navbar/>
                    <EndCard selected={selected} setSelected={toggleSelected}/>
                </>
                )
              }  else {
                   return (
                   <>
                    <Navbar/>
                    <Content/>
                   </>
                   )
                }
            } else if (lists.length === 1){
            
                if (activeTopic < 0){
                 
                  return  (
                  <>
                      <Navbar/>
                      <BeginCard selected={selected} setSelected={toggleSelected}/>
                  </>)
                } else if ( activeTopic > lists[selectedList].listItems.length -1){
                  return (
                  <>
                      <Navbar/>
                      <EndCard/>
                  </>
                  )
                }  else {
                     return (<><Navbar/> <Content/></>)
                  }
            } else {
              return  (
                <>
                   <Navbar/>
                   {isLoading ? <Loading/> : <Landing/>}
                </>)
             
            }
          
            
         
 
}

export default ChecklistApp
