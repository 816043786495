const theme = () => ({
  msgBackdrop:{
    position:"absolute",
    background:"rgba(0,0,0,.4)",
    width:"100%",
    height:"100%",
    //opacity:0,
    //zIndex:"-1000"
  },
  typeSpace:{
      position:"absolute",
      bottom:0,
      marginLeft:"0.25rem",
      borderRadius:"5px 5px 0 0 ",
      width:"calc(100% - 0.5rem)",
      //height:"5rem",
      background:"rgba(26,39,51,1) !important",
  },
  typeSpaceDesciption:{
      padding:"1rem 1.5rem",
      fontSize:"0.8rem",
      color:"#fff",
  },
  inputSpace:{
      display:"flex",
      justifyContent:"space-between",
      padding:"0.5rem 1.5rem 0.5rem 1.5rem",
      // border:"2px dashed red",
  },
  msgInput:{
      background:"transparent",
      outline:"none",
      border:"0",
      paddingBottom:"0.3rem",
      borderBottom:"2px solid rgba(255,255,255,0.3)",
      color:"#fff",
      fontSize:"1.1rem",
      resize:"none",
      width:"100%",
      //lineHeight:"1.5rem",
  },
  msgButtonDiv:{
      display:"flex",
      justifyContent:"flex-end",
      padding:"0rem 1.2rem 0.5rem 0rem",
  },
  msgButton:{
    background:"rgba(255,255,255,0.1) !important",
    padding:"0.25rem !important",
    borderRadius:"5px",
    color:"rgba(156, 246, 246,1) !important",
}
});

export default theme