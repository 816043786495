
import React, { useContext, useEffect, useRef, useState } from 'react'
import {ListContext} from "../context/ListState";
import {SendContext} from "../context/SendState";
import {LogContext} from "../context/LogState";
import {createUseStyles} from 'react-jss';
import {gsap, TimelineMax} from "gsap";
import {CircularProgress} from "@material-ui/core"
import globalStyles from "../styles/globalStyles";
import beginStyles from "../styles/beginStyles";
import { API, Auth, Hub } from"aws-amplify";
import { v4 as uuidv4 } from 'uuid';



import {AssignmentTurnedIn} from "@material-ui/icons"
import "../styles/App.css"

const useStyles = createUseStyles({
    ...globalStyles(),
    ...beginStyles(),
  })

function BeginCard({selected, setSelected}) {
    const classes = useStyles();
    const beginContainerRef = useRef();
    const beginWrapperRef = useRef();
    const beginLoadingRef = useRef();
    const beginTitleRef = useRef();
    const beginDetailsRef = useRef();
    const beginButtonRef = useRef();
    const listButtonRef = useRef();
    const beginIconRef = useRef();
    let tlLoadingAnimation = new TimelineMax();
    const [{
        lists,
        selectedList
    },
  listDispatch,
    ] = useContext(ListContext);
    const [{
        queryParams,
    },
   sendDispatch,
    ] = useContext(SendContext);
    const [{
        log
    },
   logDispatch,
    ] = useContext(LogContext);

    const {subject, description, listItems} = lists[selectedList];
    const [listStatus, setListStatus] = useState(0)
    const [paramStatus, setParamStatus] = useState(0)
    
    useEffect(()=>{
      // console.log(queryParams)
    },[])

    useEffect(()=>{
        if (listItems !== "undefined"){
            // console.log("listItems", list[0].listItems.length)
            setListStatus(lists[selectedList].listItems.length)
        }
    },[listItems])

    useEffect(()=>{
        if (listStatus > 0){
            changeLoading()
        }
    },[listStatus])
    useEffect(()=>{
     // console.log("log", log)
    },[log])

    function changeLoading(){
        tlLoadingAnimation
        .to([beginLoadingRef.current],0.5,{
            opacity:0,
            scale:0.95,
        })
        .set([beginTitleRef.current,beginDetailsRef.current,beginButtonRef.current, listButtonRef.current],{
            scale:0.95,
            ease:"power3.out"
        })
        .staggerTo([beginTitleRef.current,beginDetailsRef.current,beginButtonRef.current, listButtonRef.current],0.75,{
            scale:1,
            opacity:1,
            ease:"power1.out"
        },0.2)
    }

    function transitionBegin (){
        gsap.to([beginWrapperRef.current],0.5,{
            opacity:0,
            ease:"power3_out",
        })
        gsap.set([beginContainerRef.current],{
            css:{
                background:"#212931"
            },
            ease:"power3_out",
        })
        gsap.to([beginContainerRef.current],0.5,{
            delay:0.2,
            css:{
                background:"#495a69"
            },
            ease:"power3_out",
        })
    }
    function goToCards(){
       transitionBegin();
        setTimeout(function(){
            listDispatch({type:"activeTopic", activeTopic: 0})
        },500)
        
    }
    async function goToList(val){
        // console.log(setSelected)
        //await sendDispatch({type:"queryParams", queryParams:""})
        await listDispatch({type:"activeTopic", activeTopic: -1})
        setSelected(false)
    }
    function totalCards(){
        let count = 0;
        lists[selectedList].listItems.map((item, index)=>{
            item.steps.map((step,index)=>{
                count = count + 1
            })
        })
       return count
    }   
   
    async function beginLog(){
       
        const sessionId = uuidv4();
        const time = Date.now()
        const beginLogs = {
            id:sessionId,
            listName:subject,
            location:{
                building:lists[selectedList].location.building,
                room:lists[selectedList].location.room
            },
            time: new Date(),
            timestamp: time,
        }
        logDispatch({type:"logBegin", payload:beginLogs})
        goToCards()
        startSessionLog(beginLogs)
       
    }

    async function startSessionLog(e){
       const {listName, location,id,time,timestamp} = e;
        const myPost = {
            body:{
                ...log,
                id,
                listName,
                location,
                time:[time],
                timestamp:[timestamp],
                
            },
            response:true,
            
        }
        try{
            const res = await API.post("listLogsApi3","/listlogs3",myPost)
        } catch(err){
            console.log("error sending begin session log", err)
        }
    }
        return (
            <div className={classes.beginContainer} ref={beginContainerRef}>
                 <div className={classes.header}></div>
                 <div className={classes.locationTitle}>{lists[selectedList].location.building.toUpperCase()}-{lists[selectedList].location.room.toUpperCase()}</div>

                <div className={classes.beginWrapper} ref={beginWrapperRef}>
                    <div className={classes.beginLoading} ref={beginLoadingRef}>
                        {/* <CircularProgress className={classes.beginLoader}/>
                        <span>Loading</span> */}
                    </div>

                <div className={classes.beginTitleDiv} ref={beginTitleRef}>
                            <div className={classes.beginTitle}>{subject}</div>
                            <div className={classes.beginDescription}>{description}</div>
                </div>

                        <div className={classes.beginListDiv} ref={beginDetailsRef}>
                            <div className={classes.gatherList} >
                                <div className={classes.beginRow}>
                                    <div></div>
                                    <div className={classes.beginBullet}><span className={classes.bulletNumberColor}>-</span> minutes</div>
                                    <div></div>
                                </div>
                                <div className={classes.beginRow}>
                                    <div className={classes.beginBullet}><span className={classes.bulletNumberColor}>{lists[selectedList].listItems.length} </span>topics</div>
                                    <div className={classes.beginBullet}><span className={classes.bulletNumberColor}>{totalCards()}</span> cards</div>
                                </div>
                                <div className={classes.beginRow}>
                                    <div className={classes.beginBullet}><span className={classes.bulletNumberColor}>-</span> skills</div>
                                    <div className={classes.beginBullet}><span className={classes.bulletNumberColor}>-</span> resources</div>
                                </div>
                            </div>
                        </div>
                        <button disabled={listStatus > 0 ?false:true} className={classes.beginButton} onClick={()=>beginLog()} ref={beginButtonRef}>Begin</button>
                        {lists.length > 1 ? <button className={classes.beginBackButton}  onClick={()=>goToList()} ref={listButtonRef}>Back</button> : ""}

                </div>
                <div className={classes.footer}></div>
            </div>
        )
    
        
    

}

export default BeginCard
